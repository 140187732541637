import { ChangeDetectionStrategy, Component, inject, input, ViewEncapsulation } from '@angular/core';
import { ApiTypes } from '@hyundai/ng-common-lib';
import { Store } from '@ngrx/store';
import {
    NgxPxCarouselAwesomePlusComponent,
    NgxPxCarouselAwesomePlusItemDirective,
} from '@pixelgenau/ngx-carousel-awesome-plus';
import { VehicleCardComponent } from '../../components/vehicle-card/vehicle-card.component';

@Component({
    selector: 'lib-similar-vehicles',
    standalone: true,
    imports: [NgxPxCarouselAwesomePlusComponent, NgxPxCarouselAwesomePlusItemDirective, VehicleCardComponent],
    templateUrl: './similar-vehicles.component.html',
    styleUrl: './similar-vehicles.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'lib-similar-vehicles',
    },
})
export class SimilarVehiclesComponent {
    store = inject(Store);

    title = input.required();
    similarVehicles = input.required<Omit<ApiTypes.Vehicles, 'envkvString'>>();
}
