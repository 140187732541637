.lib-similar-vehicles .carousel {
    position: unset !important;
    &__content {
        position: unset !important;
    }

    &__indicator {
        position: absolute !important;
        @apply bottom-[155px] left-1/2 -translate-x-1/2;
        margin: 0 !important;

        &-button {
            &--active {
                background-color: #00aad2 !important;
            }
        }
    }

    &__controls {
        @apply bottom-1/2 right-[1245px];
        width: auto !important;
        top: unset !important;
        transform: translateX(-50%) !important;
    }

    &__control {
        &--prev {
            left: -75px;
        }
        &--next {
            right: -75px;
        }
    }
}
